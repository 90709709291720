import { getRequest, postRequest, userPostRequest, userGetRequest, OwnerPostRequest, OwnerGetRequest, propertyOwnerRequestForm, vehicleGetRequest, vehiclePostRequest, vehicleRequestForm, userPostRequestFygaro } from "../coreFIles/helper";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////                                 Property owner Actions                       ///////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const RegisterAction = (data) => {
   return postRequest('userRegister', data).then(res => { return res.data })
}

export const LoginAction = (data) => {
   return postRequest('login', data).then(res => { return res.data })
}

export const ResendEmailAction = (data) => {
   return postRequest('resendmail', data).then(res => { return res.data })
}

export const verifyAccountAction = (data) => {
   return postRequest('verifyAccount', data).then(res => { return res.data })
}

export const ForgotPasswordAction = (data) => {
   return postRequest('forgotPassword', data).then(res => { return res.data })
}

export const ResetPasswordAction = (data) => {
   return postRequest('resetPassword', data).then(res => { return res.data })
}

export const getProfileAction = (data) => {
   return postRequest('getUserProfile', data).then(res => { return res.data })
}

export const UpdateProfileAction = (data) => {
   return propertyOwnerRequestForm('updateProfile', data).then(res => { return res.data })
}

export const updatePasswordAction = (data) => {
   return postRequest('updatePassword', data).then(res => { return res.data })
}

export const getPropertyTypeDetailAction = (data) => {
   return getRequest('getPropertyTypeDetail', data).then(res => { return res.data })
}
export const getBlogImagesPostDetailsAction = (data) => {
   return getRequest('getBlogImagesPostDetails', data).then(res => { return res.data })
}
export const getTeamAction = (data) => {
   return getRequest('getTeam', data).then(res => { return res.data })
}

export const getPropertySharedOccupancyDetailAction = (data) => {
   return getRequest('getPropertySharedOccupancyDetail', data).then(res => { return res.data })
}
export const getFaqAction = (data) => {
   return getRequest('getFaq', data).then(res => { return res.data })
}
export const getPolicyDetailsAction = (data) => {
   return getRequest('getPolicyDetails', data).then(res => { return res.data })
}
export const getPromisesDetailsAction = (data) => {
   return getRequest('getPromisesDetails', data).then(res => { return res.data })
}

export const getPromiseDetailsActionById = (data) => {
   return getRequest(`getPromisesDetailsbyId/${data}`).then((res) => {
      return res.data;
   });
};
export const getHouseRulesAction = (data) => {
   return getRequest('getHouseRules', data).then(res => { return res.data })
}
export const getPropertySharedCategoryDetailAction = (data) => {
   return getRequest('getSharedOccupancyExtra', data).then(res => { return res.data })
}
export const getRoomTypesDetailsAction = (data) => {
   return getRequest('getRoomTypesDetails', data).then(res => { return res.data })
}

export const getPropertyAmenitiesDetailAction = (data) => {
   return getRequest('getPropertyAmenitiesDetail', data).then(res => { return res.data })
}
export const getPropertyStandoutAmenitiesDetailAction = (data) => {
   return getRequest('getPropertyStandoutAmenitiesDetail', data).then(res => { return res.data })
}
export const getSafetyItemsdetailsAction = (data) => {
   return getRequest('getSafetyItemsdetails', data).then(res => { return res.data })
}

export const getPropertyPurchaseTypeAction = (data) => {
   return OwnerGetRequest('getPropertyPurchaseType', data).then(res => { return res.data })
}

export const getPropertyCategoryDetailAction = (data) => {
   return getRequest('getPropertyCategoryDetail', data).then(res => { return res.data })
}

export const getPropertyMoreDetailAction = (data) => {
   return getRequest('getPropertyMoreDetail', data).then(res => { return res.data })
}
export const getHomePageDetailsAction = (data) => {
   return getRequest('getHomePageDetails', data).then(res => { return res.data })
}
export const getTopDestinationGalleryAction = (data) => {
   return postRequest('getTopDestinationGallery', data).then(res => { return res.data })
}


export const insertIntoBlogCommentAction = (data) => {
   return userPostRequest('insertIntoBlogComment', data).then(res => { return res.data })
}


export const getSubscriptionPlanAction = (data) => {
   return getRequest('getSubscriptionDetail', data).then(res => { return res.data })
}

export const PropertyOwnerRegister = (data) => {
   return OwnerPostRequest('registration', data).then(res => { return res.data })
}

export const getPropertyDetailAction = (data) => {
   return OwnerPostRequest('getPropertyDetailById', data).then(res => { return res.data })
}

export const cancelPropertyMannualBooking = (data) => {
   return OwnerPostRequest('cancelPropertyMannualBooking', data).then(res => { return res.data })
}


export const getPropertyAvailableDataAction = (data) => {
   return OwnerPostRequest('checkavilaberoom', data).then(res => { return res.data })
}

export const getPropertyAllDetaillistAction = (data) => {
   return OwnerGetRequest('getPropertyAllDetailList', data).then(res => { return res.data })
}

export const getPropertyListAction = (data) => {
   return OwnerPostRequest('getPropertyDetail', data).then(res => { return res.data })
}

export const deleteListedProperty = (data) => {
   return OwnerPostRequest('deleteListedProperty', data).then(res => { return res.data })
}

export const propertyOwnerAddProperty = (data) => {
   return propertyOwnerRequestForm('addPropertyDetail', data).then(res => { return res.data })
}

export const getUserProfileAction = (data) => {
   return OwnerGetRequest('getPropertyOwnerProfile', data).then(res => { return res.data })
}

export const getUpcomingBookingAction = (data) => {
   return OwnerGetRequest('getUpcomingBooking', data).then(res => { return res.data })
}

export const getNotificationAction = (data) => {
   return OwnerGetRequest('getUserNotification', data).then(res => { return res.data })
}

export const getPurchasedPlanAction = (data) => {
   return OwnerGetRequest('getPurchasedPlan', data).then(res => { return res.data })
}

export const changePasswordAction = (data) => {
   return OwnerPostRequest('changePassword', data).then(res => { return res.data })
}

export const getBrowserHistoryAction = (data) => {
   return OwnerGetRequest('getDeviceDetail', data).then(res => { return res.data })
}

export const deviceDetailDeleteAction = (data) => {
   return OwnerPostRequest('deviceDetailDelete', data).then(res => { return res.data })
}

export const getPaymentMethodsAction = (data) => {
   return OwnerGetRequest('getPaymentMethod', data).then(res => { return res.data })
}

export const getCountryCodeAction = (data) => {
   return OwnerGetRequest('getCountry', data).then(res => { return res.data })
}

export const paymentMethodAddAction = (data) => {
   return OwnerPostRequest('insertPaymentMethod', data).then(res => { return res.data })
}
export const updatePaymentMethodAction = (data) => {
   return OwnerPostRequest('updatePaymentMethod', data).then(res => { return res.data })
}

export const deletePaymentMethodAction = (data) => {
   return OwnerPostRequest('deletePaymentMethod', data).then(res => { return res.data })
}

export const getPropertyDetailsAction = (data) => {
   return OwnerPostRequest('getPropertyInformation', data).then(res => { return res.data })
}

export const notificationsDeleteAction = (data) => {
   return OwnerGetRequest('notificationsDelete', data).then(res => { return res.data })
}

export const changePropertyStatusAction = (data) => {
   return OwnerPostRequest('changePropertyStatus', data).then(res => { return res.data })
}

export const readNotificationAction = (data) => {
   return OwnerGetRequest('readNotification', data).then(res => { return res.data })
}

export const bookingByPropertyOwnerAction = (data) => {
   return propertyOwnerRequestForm('bookingByPropertyOwner', data).then(res => { return res.data })
}

export const checkInCheckOutAction = (data) => {
   return OwnerPostRequest('checkInCheckOutStatusUpdateByOwner', data).then(res => { return res.data })
}

export const checkPickUpDropOffAction = (data) => {
   return OwnerPostRequest('checkPickUpDropOff', data).then(res => { return res.data })
}

export const getBookingListAction = (data) => {
   return OwnerPostRequest('getBookingList', data).then(res => { return res.data })
}

export const getManualBookingListAction = (data) => {
   return OwnerGetRequest('getManualBookingList', data).then(res => { return res.data })
}
export const getManualBookingTrxListAction = (data) => {
   return OwnerPostRequest('getManualBookingTrxList', data).then(res => { return res.data })
}
export const addManualBookingTrxAction = (data) => {
   return OwnerPostRequest('addManualBookingTrx', data).then(res => { return res.data })
}

export const deleteManualTrxAction = (data) => {
   return OwnerPostRequest('deleteManualBookingTrx', data).then(res => { return res.data })
}


export const cancelVehicleMannualBooking = (data) => {
   return OwnerPostRequest('cancelVehicleMannualBooking', data).then(res => { return res.data })
}



export const logoutAction = (data) => {
   return OwnerGetRequest('logoutAction', data).then(res => { return res.data })
}

export const getReviewAction = (data) => {
   return OwnerGetRequest('getPropertyOwnersPropertyReview', data).then(res => { return res.data })
}
export const getVehicleOwnersVehicleReviewAction = (data) => {
   return OwnerGetRequest('getVehicleOwnersVehicleReview', data).then(res => { return res.data })
}

export const getSalesPropertyAction = (data) => {
   return OwnerGetRequest('getSalesPropertyAction', data).then(res => { return res.data })
}

export const getDashboardStatisticsAction = (data) => {
   return OwnerGetRequest('getDashboardStatisticsAction', data).then(res => { return res.data })
}

export const getDashboardChartDataAction = (data) => {
   return OwnerPostRequest('getDashboardChartData', data).then(res => { return res.data })
}

export const getAboutUsAction = (data) => {
   return getRequest('getAboutUs', data).then(res => { return res.data })
}
export const getWhatWeOfferAction = (data) => {
   return getRequest('getWhatWeOffer', data).then(res => { return res.data })
}

export const getPrivacyPolicyAction = (data) => {
   return OwnerGetRequest('privacyPolicy', data).then(res => { return res.data })
}

export const getTermsConditionAction = (data) => {
   return OwnerGetRequest('termsConditions', data).then(res => { return res.data })
}

export const insertDigitalSignatureAction = (data) => {
   return OwnerPostRequest('insertDigitalSignature', data).then(res => { return res.data })
}

export const getDigitalSignatureAction = (data) => {
   return OwnerGetRequest('getDigitalSignature', data).then(res => { return res.data })
}

export const uploadKycDocumentAction = (data) => {
   return propertyOwnerRequestForm('uploadKycDocument', data).then(res => { return res.data })
}

export const getPlanPurchaseDetailsAction = (data) => {
   return OwnerPostRequest('getPlanPurchaseDetails', data).then(res => { return res.data })
}

export const getRoomsTypeAction = (data) => {
   return OwnerGetRequest('getRoomType', data).then(res => { return res.data })
}

export const getPropertyTypesAction = (data) => {
   return OwnerPostRequest('getPropertyTypes', data).then(res => { return res.data })
}

//////////////////////////////////////////////////////////////////////////////////////////////////////
///////                            Owner Vehicle Actions                             ////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////

export const getVehicleTypeAction = (data) => {
   return vehicleGetRequest('getVehicleTypes', data).then(res => { return res.data })
}

export const getvehicleFeaturesAction = (data) => {
   return vehicleGetRequest('getvehicleFeatures', data).then(res => { return res.data })
}

export const getvehicleMoreAction = (data) => {
   return vehicleGetRequest('getvehicleMore', data).then(res => { return res.data })
}

export const vehicleAddByOwnerAction = (data) => {
   return vehicleRequestForm('vehicleAddByOwner', data).then(res => { return res.data })
}

export const vehicleEditByOwnerAction = (data) => {
   return vehicleRequestForm('vehicleEditByOwner', data).then(res => { return res.data })
}

export const removeVehicleImageAction = (data) => {
   return vehiclePostRequest('removeVehicleImage', data).then(res => { return res.data })
}

export const updateVehiclePhotosAction = (data) => {
   return vehicleRequestForm('updateVehiclePhotos', data).then(res => { return res.data })
}

export const getVehicleListAction = (data) => {
   return vehiclePostRequest('getVehicleListByOwner', data).then(res => { return res.data })
}

export const deleteListedVehcile = (data) => {
   return vehiclePostRequest('deleteListedVehcile', data).then(res => { return res.data })
}
export const getVehicleDetailAction = (data) => {
   return vehiclePostRequest('getVehicleDetail', data).then(res => { return res.data })
}

export const changeVehicleStatusAction = (data) => {
   return vehiclePostRequest('changeVehicleStatus', data).then(res => { return res.data })
}

export const getVehicleBookingListAction = (data) => {
   return vehiclePostRequest('getVehicleBookingList', data).then(res => { return res.data })
}

export const rideStartEndAction = (data) => {
   return vehiclePostRequest('rideStartEndActionUpdateByOwner', data).then(res => { return res.data })
}

export const getVehicleBookingDetailsAction = (data) => {
   return vehiclePostRequest('getVehicleBookingDetails', data).then(res => { return res.data })
}

export const getVehicleDashboardChartDataAction = (data) => {
   return vehiclePostRequest('getVehicleDashboardChartData', data).then(res => { return res.data })
}

export const getVehicleDashboardStatisticsAction = (data) => {
   return vehicleGetRequest('getVehicleDashboardStatistics', data).then(res => { return res.data })
}

export const bookingByVehicleOwnerAction = (data) => {
   return vehicleRequestForm('bookingByVehicleOwner', data).then(res => { return res.data })
}

//////////////////////////////////////////////////////////////////////////////////////////////////////
///////                                 Users Actions                                ////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////

export const getUsersBookingListAction = (data) => {
   return userPostRequest('getBookingDetails', data).then(res => { return res.data })
}

export const getBuyAndLeasePropertyDetailsAction = (data) => {
   return userPostRequest('getBuyAndLeasePropertyDetails', data).then(res => { return res.data })
}

export const getWishListAction = (data) => {
   return userGetRequest('getWishList', data).then(res => { return res.data })
}

export const removeFromWishlistAction = (data) => {
   return userPostRequest('removeFromWishlist', data).then(res => { return res.data })
}

export const addWishlistAction = (data) => {
   return userPostRequest('addWishlist', data).then(res => { return res.data })
}

export const getPropertyDetailForCancelAction = (data) => {
   return userPostRequest('getPropertyDetailForCancel', data).then(res => { return res.data })
}

export const insertBookingDetailsAction = (data) => {
   return userPostRequest('insertBookingDetails', data).then(res => { return res.data })
}
export const checkBookingDetailsAction = (data) => {
   return userPostRequest('checkBookingDetails', data).then(res => { return res.data })
}

export const cancelBookingAction = (data) => {
   return userPostRequest('cancelBooking', data).then(res => { return res.data })
}

export const bookingFeedbackAction = (data) => {
   return userPostRequest('addFeedbackRating', data).then(res => { return res.data })
}

export const getTopDestinationAction = (data) => {
   return userPostRequest('getTopDestination', data).then(res => { return res.data })
}

export const getLocationByMoodAction = (data) => {
   return userGetRequest('getLocationByMood', data).then(res => { return res.data })
}

export const getLocationByMoodPropertiesAction = (data) => {
   return userPostRequest('getLocationByMoodProperties', data).then(res => { return res.data })
}

export const getMoreSuggestionAction = (data) => {
   return userPostRequest('getMoreSuggestion', data).then(res => { return res.data })
}

export const getTransactionHistoryAction = (data) => {
   return userGetRequest('getTransactionHistory', data).then(res => { return res.data })
}
export const getWeeklyPropertyAction = (data) => {
   return userGetRequest('getWeeklyProperty', data).then(res => { return res.data })
}

export const getDateWiseBookingCountAction = (data) => {
   return OwnerGetRequest('getDateWiseBookingCount', data).then(res => { return res.data })
}
export const getDateWiseVehicleBookingCountAction = (data) => {
   return OwnerGetRequest('getDateWiseVehicleBookingCount', data).then(res => { return res.data })
}

export const getDateWiseBookingDetailsAction = (data) => {
   return OwnerPostRequest('getDateWiseBookingDetails', data).then(res => { return res.data })
}
export const getDateWiseVehicleBookingDetailsAction = (data) => {
   return OwnerPostRequest('getDateWiseVehicleBookingDetails', data).then(res => { return res.data })
}

export const getVehicleAllDetailListAction = (data) => {
   return userGetRequest('getVehicleAllDetailList', data).then(res => { return res.data })
}

export const getVehicleDetailActionbyid = (data) => {
   return userPostRequest('getVehicleDetailById', data).then(res => { return res.data })
}

export const getVehicleDetailForCancelAction = (data) => {
   return userPostRequest('getVehicleDetailForCancel', data).then(res => { return res.data })
}

export const getUsersVehicleBookingListAction = (data) => {
   return userPostRequest('getUsersVehicleBookingList', data).then(res => { return res.data })
}

export const vehicleCancelBookingAction = (data) => {
   return userPostRequest('vehicleCancelBooking', data).then(res => { return res.data })
}

export const checkvehicleBookingDetailsAction = (data) => {
   return userPostRequest('checkvehicleBookingDetails', data).then(res => { return res.data })
}

export const insertvehicleBookingDetailsAction = (data) => {
   return userPostRequest('insertvehicleBookingDetails', data).then(res => { return res.data })
}

export const getVehicleAvailableDataAction = (data) => {
   return userPostRequest('checkavilabeVehcile', data).then(res => { return res.data })
}

export const getFygaroJwt = (data) => {
   return userPostRequest('getFygaroJwt', data).then(res => { return res.data })
}

export const MMGCheckoutPage = (data) => {
   return userPostRequest('MMGCheckoutPage', data).then(res => { return res.data })
}



export const getFygaroJwtForApp = (data) => {
   return userPostRequestFygaro('getFygaroJwt', data).then(res => { return res.data })
}

// blockchain api


export const getPropertyTransactionDetailsByHashAction = (data) => {
   return postRequest('getPropertyTransactionDetailsByHash', data).then(res => { return res.data })
}
export const getpropertybypropertyIdBlockchainAction = (data) => {
   return postRequest('getpropertybypropertyIdBlockchain', data).then(res => { return res.data })
}

export const getVehicleTransactionDetailsByHashAction = (data) => {
   return postRequest('getVehicleTransactionDetailsByHash', data).then(res => { return res.data })
}
export const getvehiclebyvehicleIdBlockchainAction = (data) => {
   return postRequest('getvehiclebyvehicleIdBlockchain', data).then(res => { return res.data })
}

export const getBlogPostDetailsAction = (data) => {
   return getRequest('getBlogPostDetails', data).then(res => { return res.data })
}

export const getBlogDetailAction = (data) => {
   return postRequest('getBlogDetailsById1', data).then(res => { return res.data })
}
export const getBlogCommentsAction = (data) => {
   return getRequest('getBlogComments', data).then(res => { return res.data })
}

export const getSubscriptionplanByUserIdAction = (data) => {
   return postRequest('getPlanSubscriptionDetailById', data).then(res => { return res.data })
}

export const getRefundHistoryAction = (data) => {
   return getRequest('getRefundHistory', data).then(res => { return res.data })
}

export const propertyBookingAction = (data) => {
   return userPostRequest('propertyBookingWithFygaro', data).then(res => { return res.data })
}

//-----------------------------|| VEHICLE ||----------------------------


export const getVehicleManualBookingList = (data) => {
   return vehicleGetRequest('getVehicleMannualBookingList', data).then(res => { return res.data })
}

export const getVechileReceivableReport = (data) => {
   return vehiclePostRequest('getVechileReceivableReport', data).then(res => { return res.data })
}
