const config = {
  baseUrl: "/",
  //  serverPath: "http://localhost:8000/api/",

  // Live Server
  // serverPath: "https://propertynu.com/api/",
  // imageUrl: "https://propertynu.com/media/",

   serverPath: "https://dev.propertynu.com/api/",
   imageUrl: "https://dev.propertynu.com/media/",

  // DO NOT CHANGE //
  REACT_APP_FB_APP_ID: process.env.REACT_APP_FB_APP_ID,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  stripePrivateKey: "",
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  fygaroJWTEncryptionKey: process.env.REACT_APP_FYGARO_JWT_ENCRYPTION_KEY

};
export default config;
