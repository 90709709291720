
import React, { useState, useEffect } from 'react'
import config from '../../config'
import { Link } from "react-router-dom";
import '../../generalPages/generalCSS/thankyou.css'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { propertyBookingAction } from "../../Action/user.action";
import Cookies from "js-cookie";

const PaymentSuccess = () => {
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        console.log('params', params.get('checkout_response_token'));
        
        const responseToken = params.get("checkout_response_token"); // Get JWT token from the URL
        localStorage.setItem("responseTokenMMG", responseToken);
        Cookies.set("responseTokenMMG", JSON.stringify(responseToken));
        let paymentRes = JSON.stringify(responseToken)
        propertyBookingAPI(paymentRes)

    }, []);

    const propertyBookingAPI = async (paymentRes) => {
        let res = await propertyBookingAction({ 'paymentRes': paymentRes });
        if (res.success) {
            setIsSuccess(true)
        } else {
            setIsSuccess(false)
        }
    };

    return (
        <>
            <section className="thankyou-section pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row className='justify-content-center '>
                        <Col xl={9} lg={9}>
                            <div className="thankyou">
                                <div className="text-center">
                                    <div className="login-logo mb-5">
                                        <img
                                            src="assets/images/logo/logo.webp"
                                            alt="logo"
                                        />
                                    </div>
                                    <div className='pt-4 pb-4'>
                                        {/* {isSuccess == true ?
                                            <>
                                                <h3 className='mb-4'><b style={{ color: 'blue' }}>Success!!</b></h3>
                                                <p className='mb-4 text-grey'>Your payment has been completed successfully.</p>
                                            </>
                                            :
                                            <>
                                                <h3 className='mb-4'><b style={{ color: 'blue' }}>Failed!!</b></h3>
                                                <p className='mb-4 text-grey'>Your payment has been failed.</p>
                                            </>
                                        } */}

                                        {/* {isSuccess == true &&
                                            <> */}
                                        <h3 className='mb-4'><b style={{ color: 'blue' }}>Success!!</b></h3>
                                        <p className='mb-4 text-grey'>Your payment has been completed successfully.</p>
                                        {/* </>
                                        } */}
                                        <Link to={`${config.baseurl}`} >
                                            <Button variant='primary'>Return to Home</Button>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <footer>
                <div className='banner-bottom'>
                    <img src='assets/images/background/bottom_banner.webp' width="100%" />
                </div>
            </footer>
        </>
    )
}

export default PaymentSuccess;
